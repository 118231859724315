import { solarRef } from '../firebase/db';
import { mapGetters, mapMutations } from 'vuex';
import { useDocumentHistory } from '../services/documentHistory';

export default {
  data: () => ({}),
  computed: {
    ...mapGetters(['solar/data', 'solar/selectedSystem'])
  },
  methods: {
    ...mapMutations(['solar/setData', 'solar/setLoading', 'solar/setSelected']),

    async saveSolarData(id, data) {
      let solarData;
      if (data) {
        solarData = this.copyObject(data);
      } else {
        solarData = this.copyObject(this[`solar/data`]);
      }
      let user = await this.currentUser();
      solarData.user = user.uid;
      solarRef.doc(id).set(solarData).then(()=>{
        const {addDocHistory} = useDocumentHistory()
        addDocHistory({
          id,
          data,
          docCollection: solarRef.id
        })
      })
    },

    async getSolarData(id) {
      this['solar/setLoading'](true);
      let data;
      try {
        data = await solarRef.doc(id).get();
        if (data.exists) {
          data = await data.data();
        } else {
          data = null;
        }
      } catch {
        data = null;
      }
      if (data) {
        this['solar/setData'](data);
      }
      this['solar/setLoading'](false);
    },

    async getAllSolarData() {
      let user = await this.currentUser();
      if (!user.uid) {
        return;
      }
      let data;
      try {
        data = await this.$axios.get(`/solar`);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
      if (!data) {
        return;
      }
      this.$store.commit('solar/setDataList', data.data);
    }
  }
};
